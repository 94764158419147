import React from "react"
import { Container } from "react-bootstrap"
import "./RightTextBlock.scss"
import { parseHTML } from "../../common/utils/utils"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const RightTextBlock = ({ text }) => {
  if (!text) return null

  return (
    <motion.div
      className="rich-text-wrap section-m-120"
      variants={contentVariant}
      initial="initial"
      animate={{
        ...contentVariant.animate,
        transition: {
          ...contentVariant.animate.transition,
          delay: 0.25,
        },
      }}
    >
      <Container className="rich-text-container">{parseHTML(text)}</Container>
    </motion.div>
  )
}

export default RightTextBlock
