import clsx from "clsx"
import { graphql } from "gatsby"
import React from "react"
import "../styles/main.scss"
import BannerListing from "../components/BannerListing/BannerListing"
import RightTextBlock from "../components/RightTextBlock/RightTextBlock"
import SEO from "../components/SEO/seo"
import { motion } from "framer-motion"
import { layoutVariant } from "../common/animations"

const StaticPage = ({ data, ...props }) => {
  const pageData = data?.strapiPage

  if (!pageData) return null

  const modules = pageData?.modules

  const { title, description } = pageData.banner_section
  const meta_title = pageData?.seo?.meta_title
  const meta_description = pageData?.seo?.meta_description

  return (
    <motion.div
      className={clsx("static-template", pageData.page_class)}
      variants={layoutVariant}
      initial="initial"
      animate="animate"
      exit="exit"
      key={pageData.strapi_id}
    >
      <SEO title={meta_title} description={meta_description} />
      <BannerListing title={title} description={description} />
      {modules?.map(module => (
        <>
          {module.strapi_component === "components.rich-text" && (
            <RightTextBlock text={module.text_content?.data?.text_content} />
          )}
        </>
      ))}
    </motion.div>
  )
}

export default StaticPage

export const query = graphql`
  query ($page_id: Int) {
    strapiPage(strapi_id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_COMPONENTS_RICH_TEXT {
          id
          strapi_id
          strapi_component
          text_content {
            data {
              text_content
            }
          }
        }
      }
    }
  }
`
